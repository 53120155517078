<!-- 
普通
<headeBar title="我的礼品卡" left-arrow @click-left="" />

只有返回
<headeBar  left-arrow @click-left="" onlyBack />

滚动变色 ../scrollHead.vue -->
<template>
    <div class="com_headerbar" v-if="appType != 'mini'" :class="headerClass" >
        <div class="toper" :class="{'only-back':onlyBack}" :style="{ background:!onlyBack&&background,opacity:opacityType=='bar'&&opacity }" ref="toper">
            <statusBar v-if="statusBar" />
            <div class="header" :style="{color}" >
                <div class="header_left" v-if="leftArrow" @click="leftclick" >
                    <slot name="left" v-if="$slots.left" />
                    <van-icon name="arrow-left" class="left_icon" v-else />
                </div>
                <div class="header_title van-ellipsis" :style="{opacity:opacityType=='title'&&opacity}" >{{title}}</div>
                <div class="header_right">
                    <slot name="right" v-if="$slots.right" />
                    <span class="right_text" v-else-if="rightText" @click="rightclick" >{{rightText}}</span>
                </div>
            </div>
        </div>
        <div v-if="placeholder" >
            <statusBar v-if="statusBar" />
            <div class="header"></div>
        </div>
    </div>
</template>

<script>
// van-nav-bar 的 api
import { appType } from '@/lib/appMethod';
import statusBar from './statusBar.vue';
import Vconsole from 'vconsole';
export default {
    components: { statusBar },
    props: {
        //占位以防止塌陷
        placeholder: {
            type: Boolean,
            default: true,
        },
        //背景色
        background: {
            type: String,
            default: '#fff',
        },
        title:{
            type: String,
            default: '',
        },
        leftArrow:{
            type: Boolean,
            default: true,
        },
        //右边文字
        rightText:{
            type: String,
            default: '',
        },
        //文字颜色
        color:{
            type:String,
            default:'#333'
        },
        //透明度
        opacity:{
            type:String|Number,
            default:1
        },
        opacityType:{
            type:String,
            default:'bar'
        },
        //显示状态栏
        statusBar:{
            type:Boolean,
            default:true
        },
        //只有后退
        onlyBack:{
            type:Boolean,
            default:false
        },
        headerClass:{
            type:String
        },
        showConsole:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            appType: appType(),
        };
    },
    mounted() {
        if (this.appType != 'mini') {
            setTimeout(()=>{
                this.$emit('setBarHeitht', this.$refs.toper.clientHeight);
            },1000)
        }
        if(this.showConsole){
            let startTopAbsolute = 0,distanceY=0;
            function start(event){
                const touch = event.touches ? event.touches[0] : event;
                startTopAbsolute=touch.clientY;
            }
            function move(event){
                const touch = event.touches ? event.touches[0] : event;
                distanceY = startTopAbsolute - touch.clientY;
            }
            function end(event){
                if(distanceY<-200&&event.touches.length==1){
                    new Vconsole();
                    document.body.removeEventListener('touchstart',start);
                    document.body.removeEventListener('touchmove',move);
                    document.body.removeEventListener('touchend',end);
                }
            }
            document.body.addEventListener('touchstart',start);
            document.body.addEventListener('touchmove',move);
            document.body.addEventListener('touchend',end);
        }
    },
    methods:{
        leftclick(){
            this.$emit('click-left');
        },
        rightclick(){
            this.$emit('click-right');
        }
    }
};
</script>

<style  lang="less" scoped >
.com_headerbar {
    .toper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
    }

    .header {
        position: relative;
        display: flex;
        align-items: center;
        height: 92px;
        color: #333;
        .lr{
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            padding: 0 32px;
            cursor: pointer;
            font-size: 28px;
        }
        .header_left {
            .lr();
            .left_icon {
                font-size: 46px;
            }
        }
        .header_title {
            max-width: 60%;
            margin: 0 auto;
            font-size: 36px;
        }
        .header_right {
            .lr();
            right: 0;
        }
    }
    .only-back{
        width: 110px;
        .header_title,.header_right{
            display: none;
        }
    }
}
</style>