<template>
	<div class="page-private-accept" v-show="hasShow">
		<headBar ref="headerHeight" title="邀请有礼" left-arrow @click-left="newAppBack" />
		<div class="userline">
			<div class="userhead" :class="{ headbg: !inviterUserHeadPortrait }">
				<img :src="inviterUserHeadPortrait" v-if="inviterUserHeadPortrait" />
			</div>
			<div class="userinfo">
				<div class="username ellipsis">{{ inviterUserName || '--' }}</div>
				<div class="utips">邀你上私教</div>
			</div>
			<img src="https://img.chaolu.com.cn/ACT/private-invite-202307/accept/logo.png" class="logo" />
		</div>

		<div class="bg">
			<div class="sub" @click="acceptInvite" v-if="status == 0">
				接受邀请
			</div>
			<div class="sub" @click="jumpApp(1)" v-else>
				<template v-if="bindUserName">
					<div class="bt ellipsis">已绑定邀请人{{ bindUserName }}</div>
					<img class="to" src="https://img.chaolu.com.cn/ACT/private-invite-202307/accept/to.png" />
				</template>
				{{ status == 5 ? '接受邀请' : '立即约课' }}
			</div>
		</div>
		<div class="recommend" v-if="teacherInfo.name">
			<img src="https://img.chaolu.com.cn/ACT/private-invite-202307/accept/recommend.png" class="head" />
			<div class="rbody">
				<div class="techerline">
					<div class="teacher-head">
						<img :src="teacherInfo.headImg" v-if="teacherInfo.headImg" />
					</div>
					<div class="teacher-info">
						<div class="teacher-name">{{ teacherInfo.name }}</div>
						<div class="labels">
							<div class="label-model" v-for="item in teacherInfo.coachLabels">{{ item }}</div>
						</div>
						<div class="intro">{{ teacherInfo.detail }}</div>
					</div>
				</div>
				<div class="yk" @click="jumpApp">约TA课</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	newAppBack,
	initBack,
	defaultApp,
	gotoCoachDetail,
	gotoAppPage
} from '@/lib/appMethod';
import headBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import wx from "weixin-js-sdk";
export default {
	components: {
		headBar
	},
	mixins: [userMixin],
	name: "accept",
	data() {
		return {
			inviteUid: '',
			bindTeacherId: '',
			status: '',// 0-可以接受邀请， 1-接受邀请成功 ，2-非新用户， 3-用户已被绑定其他用户，4-被邀请人为续传用户 5-不能接受自己邀请 ，6-已经领取 , 7-已被续传绑定 , 8-已在其他渠道领券 ， 9-活动结束
			expireTime: '',
			content: '',
			inviterUserName: '',
			inviterUserHeadPortrait: '',
			bindUserName: '',
			rewardImg: '',
			rewardName: '',
			teacherInfo: {
				coachLabels: [],
				photos: [],
			},
			hasShow:false
		}
	},
	async created() {
		initBack();
		if (this.appTypeStr !== 'mini' && this.locationUrl == 'https://web.chaolu.com.cn') {
			this.$dialog.alert({
				title: '提示',
				message: '请使用微信扫码',
				overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
			}).then(() => {
				this.newAppBack();
			})
			return;
		}
		this.inviteUid = this.$route.query.inviteUid || ''
		this.bindTeacherId = this.$route.query.teacherId || ''
		await this.$getUserId();
		this.acceptInviteCheck()
		if (this.bindTeacherId) {
			this.getTeacher()
		}
	},
	methods: {
		newAppBack,
		acceptInviteCheck() {
			this.$axios.post(`${this.baseURLApp}/newPer/invitation/acceptInviteCheck`, {
				"hasWx": true,
				"token": this.token,
				"userId": this.userId,
				referrerId: this.inviteUid,
				isNewUser: 0
			}).then(r => {
				this.status = r.data.status

				// if(process.env.NODE_ENV === "development"){
				//     this.status = 0
				// }


				this.content = r.data.content
				this.bindUserName = r.data.userName
				this.expireTime = r.data.expireTime
				this.rewardName = r.data.rewardName
				this.rewardImg = r.data.rewardImg
				this.inviterUserName = r.data.inviterUserName
				this.inviterUserHeadPortrait = r.data.inviterUserHeadPortrait
				console.log(this.status);
				if (this.status === 2 || this.status === 5 || this.status === 6) {
					this.$dialog.alert({
						title: '接受邀请失败',
						message: this.content,
						confirmButtonText: '我知道了',
						confirmButtonColor: '',
						overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
					}).then(() => {
						this.jump()
					});
				}
				if (this.status === 3) {
					this.$dialog.alert({
						title: '温馨提示',
						message: `您已接受${this.bindUserName}的邀请，不可以再次接受邀请`,
						confirmButtonText: '我知道了',
						confirmButtonColor: '',
					}).then(() => {

					});
				}
				if (this.status === 4) {
					this.$dialog.alert({
						title: '温馨提示',
						message: `亲爱的续转会员，为了让您享受更优质的超鹿服务，我们为您独家升级新人邀请礼包。\n1、续转会员有效期内，邀请1位好友，最高可延长13天年卡权益；\n2、原有获得且未领取的128礼包仍可领取。\n 由此给您带来的不变，我们深感歉意。如有疑问，可拨打客服电话：\n 400-700-5678`,
						confirmButtonText: '查看新的邀请奖励',
						overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
					}).then(() => {
						defaultApp();
						if (this.appTypeStr === 'mini') {
							this.$router.push("/emptyPage?isUndo=1")
						} else {
							window.location.href = `${this.baseURL}/index/identityInvite?userId=${this.userId}`
						}

					});
					return false;
				}
				if (this.status === 7) {
					this.$dialog.alert({
						title: '接受邀请失败',
						message: this.content,
						confirmButtonText: '我知道了',
						overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
					}).then(() => {
						this.jump()
					});
					return false;
				}
				if (this.status === 9) {
					this.$dialog.alert({
						title: '提示',
						message: '暂无活动进行中',
						confirmButtonText: '我知道了',
						confirmButtonColor: '',
						overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
					}).then(() => {
						if (this.appTypeStr == 'mini') {
							wx.miniProgram.switchTab({ url: '/pages/home/index' });
						} else {
							this.newAppBack();
						}
					});
				}
				if (this.status == 11) {
					this.$dialog.alert({
						title: '接受邀请失败',
						message: this.content,
						confirmButtonText: '我知道了',
						confirmButtonColor: '',
					}).then(() => {

					});
				}
				this.hasShow=true
			})
		},
		acceptInvite() {
			if (!this.status === 0) {
				this.$toast('请稍后再试');
				return;
			}
			this.$toast.loading({
				duration: 0,
				forbidClick: true,
				loadingType: 'spinner',
			})
			this.$axios.post(`${this.baseURLApp}/newPer/invitation/acceptInvite`, {
				"hasWx": true,
				"token": this.token,
				"userId": this.userId,
				referrerId: this.inviteUid,
				isNewUser: 0
			}).then(r => {
				this.$toast.clear();
				this.status = r.data.status;
				this.bindUserName = this.inviterUserName;
				// this.acceptInviteCheck()
			})
		},
		getTeacher() {
			this.$axios.post(`${this.baseURLApp}/app/teacher/oldBringNew/getTeacherInfo`, {
				"hasWx": true,
				"token": this.token,
				"userId": this.userId,
				teacherId: this.bindTeacherId,
			}).then(r => {
				this.teacherInfo = r.data
			})
		},
		jump(path = 'superdeer-activity/private-invite-202307/index', parma = '') {
			// superdeer-activity/invite-2023  邀请页
			if (this.appTypeStr === 'mini') {
				wx.miniProgram.redirectTo({
					url: `/pages/webView/index?webUrl=${window.location.origin}/#/${path}&userId=1&token=1&location=1${parma}`,
				})
			} else {
				this.$router.replace({ path: `/${path}?userId=1&token=1&location=1&cityId=1${parma}` })
			}
		},
		jumpApp(type) {
			if (type === 1) {
				gotoAppPage(1);
			} else {
				gotoCoachDetail(this.bindTeacherId)
			}
		},
	}
}
</script>

<style scoped lang="less">
.page-private-accept {
	background: linear-gradient(to bottom, #85CC4D, #C1EB93);
	position: absolute;
	height: 100vh;
	top: 0;
	// bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;

	div {
		box-sizing: border-box;
	}

	img {
		vertical-align: top;
		width: 100%;
	}


	.userline {
		margin: 32px 56px;
		display: flex;
		align-items: center;

		.userhead {
			width: 96px;
			height: 96px;
			margin-right: 20px;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.headbg {
			background: #f5f5f5;
		}

		.userinfo {
			color: #fff;
			font-weight: bold;
			flex: 1;
			overflow: hidden;

			.username {
				font-size: 26px;
			}

			.utips {
				font-size: 28px;
				margin-top: 8px;
			}
		}

		.logo {
			width: 168px;
			height: 62px;
		}
	}




	.bg {
		width: 694px;
		height: 664px;
		margin: 0 auto;
		background-size: 100% 100%;
		background-image: url(https://img.chaolu.com.cn/ACT/private-invite-202307/accept/bg.png);
		position: relative;

		.sub {
			width: 586px;
			height: 108px;
			background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/accept/button.png);
			background-size: cover;
			position: absolute;
			bottom: 56px;
			left: 50%;
			margin-left: -293px;
			color: #9E0D00;
			font-size: 42px;
			text-align: center;
			font-weight: 800;
			padding-top: 30px;
			line-height: 42px;

			.bt {
				position: absolute;
				bottom: 100%;
				margin-bottom: 6px;
				height: 52px;
				line-height: 54px;
				background: linear-gradient(90deg, #F4FC99 0%, #85F5E1 100%);
				border-radius: 64px;
				font-size: 24px;
				color: #242831;
				font-weight: bold;
				padding: 0 24px;
				min-width: 200px;
				max-width: 406px;
				left: 0;
				transform: translateX(160px);
			}

			.to {
				position: absolute;
				bottom: 100%;
				width: 36px;
				height: 12px;
				left: 40px;
				transform: translateX(160px);
			}
		}
	}

	.recommend {
		width: 694px;
		margin: 40px auto 100px;

		.head {
			height: 136px;
		}

		.rbody {
			border-bottom-left-radius: 32px;
			border-bottom-right-radius: 32px;
			background: #fff;
			overflow: hidden;
			padding: 24px;
			padding-top: 0;
		}

		.techerline {
			display: flex;

			.teacher-head {
				width: 152px;
				height: 152px;
				border-radius: 12px;
				background: #fff;
				margin-right: 24px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.teacher-info {
				flex: 1;
				margin-bottom: 24px;

				.teacher-name {
					color: #761624;
					line-height: 1;
					font-size: 30px;
					font-weight: bold;
					margin-bottom: 16px;
				}

				.labels {
					display: flex;
					flex-wrap: wrap;

					.label-model {
						border: 1px solid #FFDAC0;
						background: #FFFAF8;
						border-radius: 6px;
						min-width: 64px;
						padding: 0 12px;
						margin-bottom: 8px;
						margin-right: 10px;
						font-size: 20px;
						color: #D35005;
						font-weight: bold;
						height: 36px;
						line-height: 36px;
					}
				}

				.intro {
					font-size: 20px;
					color: #761624;
					line-height: 32px;
					margin-top: 8px;
				}
			}
		}

		.yk {
			width: 144px;
			height: 64px;
			background: linear-gradient(90deg, #FF7F37 0%, #FF3672 100%);
			border-radius: 6px;
			text-align: center;
			float: right;
			font-size: 26px;
			color: #fff;
			font-weight: bold;
			line-height: 64px;
		}
	}

}
</style>
